<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row wrap align-center>
      <v-flex xs12>
        <v-card-title>
          Good Student Award Management
          <v-spacer></v-spacer>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-row>
              <v-col v-if="allowRegionFilter" cols="12">
                <v-select v-model="selectRegion" :items="regions" item-text="Name" item-value="RegionID" label="Region" prepend-icon="mdi-earth" 
                @change="selectCentre = null"
                clearable></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="allowCentreFilter" cols="12">
                <v-select v-model="selectCentre" :items="filteredCentres" item-text="Name" item-value="CentreID" label="Campus" prepend-icon="mdi-home-outline" 
                clearable></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="studentid" prepend-icon="mdi-magnify" label="Student ID" clearable></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="givenname" prepend-icon="mdi-magnify" label="Given Name" clearable></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="surname" prepend-icon="mdi-magnify" label="Surname" clearable></v-text-field>
              </v-col> 
            </v-row>
            <v-row>
              <v-btn color="primary" class="mr-4" @click="Search" :loading="searchLoading">Search</v-btn>
            </v-row>
          </v-card-text>
        </v-form>
      </v-flex>  
      <v-flex xs12 v-if="showTable">
        <v-card>
          <v-card flat>
            <v-card-title>
              Results
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table single-expand :expanded.sync="expanded" show-expand class="elevation-1" multi-sort :headers="summary_headers" :items="summary_results" item-key="StudentID" :search="search" :loading="searchLoading" loading-text="Loading... Please wait">
              <template v-slot:[`item.GivenName`]="{ item }">
                <span>{{ item.GivenName || item.StudentFirstName }}</span>
              </template>
              <template v-slot:[`item.Surname`]="{ item }">
                <span>{{ item.Surname || item.StudentSurname }}</span>
              </template>
              <template v-slot:[`item.Redeem`]="{ item }">
                <v-btn color="primary" @click="ShowDialog(item); expanded=[];">Redeem Now</v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                   <v-progress-linear v-if="isLoadingDetail" indeterminate color="primary"></v-progress-linear>
                   <div v-html="ShowDetail(item)"/>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-card>      
      </v-flex>
      <v-dialog v-model="showDialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Good Student Award Redemption</span>
          </v-card-title>
          <v-card-subtitle v-if="edited">
            Student Number: {{edited.StudentID}}, Student Name: {{edited.GivenName || edited.StudentFirstName}} {{edited.Surname || edited.StudentSurname}}
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation v-if="edited">
                <v-row justify="center">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="edited.AvailableCount"
                      label="Available to redeem"
                      filled
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.number="quantity"
                      label="Redeem quantity"
                      outlined
                      autofocus
                      type="number"
                      :rules="[ () => quantity <= edited.AvailableCount && quantity > 0 || 'Please check the available total.']"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>  
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text :loading="isSaving" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 
    </v-layout>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ snackbarText }}
    </v-snackbar>    
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-container> 
</template>

<script>
import { httpClient } from '../services/http-client';
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  name: 'AwardManagement',
  data () {
    return {
      summary_headers: [
        {
          text: 'Student ID',
          align: 'left',
          value: 'StudentID'
        },
        { text: 'GivenName', value: 'GivenName' },
        { text: 'Surname', value: 'Surname' },
        { text: 'Total Award', value: 'Total' },
        { text: 'Total Redeemed', value: 'RedeemedCount' },
        { text: 'Available to redeem', value: 'AvailableCount' },
        { text: '', value: 'Redeem' },
      ],
      detailed_headers: [
        {
          text: 'Student ID',
          align: 'left',
          value: 'StudentID'
        },
        { text: 'GivenName', value: 'StudentFirstName' },
        { text: 'Surname', value: 'StudentSurname' },
        { text: 'Enroled Year', value: 'EnroledYear' },
        { text: 'Campus', value: 'CentreName' },
        { text: 'Term', value: 'TermName' },
        { text: 'Homework Year', value: 'HomeworkYear' },
        { text: 'Homework Week', value: 'WeekID' },
        { text: 'Homework Subject', value: 'SubjectName' },
        { text: 'Redeemed On', value: 'RedeemedOn' },
        { text: 'Redeemed By', value: 'Username' },
      ],
      search: null,
      searchLoading: false,
      showTable: false,
      detailed_results: [],
      summary_results: [],
      selectRegion: null,
      selectCentre: null,
      overlay: false,
      studentid: null,
      givenname: null,
      surname: null,
      showDialog: false,
      edited: null,
      quantity: 0,
      isSaving: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      timeout: 6000,
      expanded: [],
      isLoadingDetail: true,
    }
  },
  computed: {
    filteredCentres() {
      if (this.selectRegion)
        return this.centres.filter(c => c.RegionID === this.selectRegion);
      return this.centres;
    },
    ...mapGetters(['allowRegionFilter', 'allowCentreFilter']),
    ...mapState(['user', 'regions', 'centres'])
  },
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        this.overlay = true;
        await Promise.all([
        this.$store.dispatch('getRegions'),
        this.$store.dispatch('getCentres')]);
      } finally {
        this.overlay = false;
      }
    },
    async Search() {
      try {
        this.showTable = true;
        this.searchLoading = true;
        var sum_url = httpClient.get('/awards/summary', {
          params: {
            RegionID: this.selectRegion,
            CentreID: this.selectCentre,
            StudentID: this.studentid,
            GivenName: this.givenname,
            Surname: this.surname
          }
        });
        var det_url = httpClient.get('/awards/detail', {
          params: {
            RegionID: this.selectRegion,
            CentreID: this.selectCentre,
            StudentID: this.studentid,
            GivenName: this.givenname,
            Surname: this.surname
          }
        });
        const [sum_resp, det_resp] = await Promise.all([sum_url, det_url]);
        if (sum_resp && sum_resp.data) {
          this.summary_results = sum_resp.data;
        } else {
          this.summary_results = [];
        }
        if (det_resp && det_resp.data) {
          this.detailed_results = det_resp.data;
        } else {
          this.detailed_results = [];
        }
      } finally {
        this.searchLoading = false;
      }
    },
    ShowDialog (item) {
      this.showDialog = true;
      this.edited = Object.assign({}, item);
      this.quantity = this.edited.AvailableCount;
    },
    close() {
      this.$refs.form.reset();
      this.edited = null;
      this.showDialog = false;
    },
    async save() {
      if (this.$refs.form.validate()) {
        try {
          this.isSaving = true;
          this.isLoadingDetail = true;
          const params = {
            StudentID: this.edited.StudentID,
            Quantity: this.quantity,
          }
          const response = await httpClient.put('/awards/redeem', params);

          if (response.status === 200)
          {
            this.snackbar = true;
            this.snackbarText = "Done. Your changes have been saved successfully.";
            this.snackbarColor = "success";

            const index = this.summary_results.findIndex(s => s.StudentID === this.edited.StudentID);
            this.summary_results[index].AvailableCount = response.data.summary.AvailableCount;
            this.summary_results[index].Total = response.data.summary.Total;
            this.summary_results[index].RedeemedCount = response.data.summary.RedeemedCount;

            this.detailed_results = this.detailed_results.filter(d => d.StudentID !== this.edited.StudentID);
            this.detailed_results = this.detailed_results.concat(response.data.detail);
          }
        } catch(err) {
          this.snackbar = true;
          this.snackbarText = `Sorry, unable to update award. ${err}`;
          this.snackbarColor = "error";
        } finally {
          this.isSaving = false;
          this.showDialog = false;
          this.isLoadingDetail = false;
        }
      }
    },
    ShowDetail(item)
    {
      try {
        var detail = '';
        var rows = this.detailed_results.filter(r => r.StudentID === item.StudentID);
        for (const r of rows) {
          detail += `${r.TermName}, ${r.HomeworkYear}, Week/Day: ${r.WeekID}, ${r.SubjectName}, Awarded By: ${r.TeacherName}, Awarded On: ${this.$options.filters.formatDateTime(r.CreatedAt)}`;
          detail += r.RedeemedOn ? `, Redeemed On: ${this.$options.filters.formatDateTime(r.RedeemedOn)}, Redeemed By: ${r.Username}`: `, Not Redeemed`;
          detail += '</br>';
        }
        return detail;
      } finally {
        this.isLoadingDetail = false;
      }
    }
  }
}
</script>

<style scoped></style>